// @flow strict
import React from "react";
import {connect} from "react-redux";
import {Redirect, Route} from "react-router";

const getPathname = (user) => {
    if (!user) {
        return "/inngang";
    }
    if (user.status === "PENDING") {
        return "/registrering/godkjenning";
    }
    if (user.status === "INSUFFICIENT") {
        return "/registrering";
    }
    if (user.status === "REJECTED") {
        return "/registrering/2";
    }
    return "/inngang";
};

export const ProtectedRoute = connect(({auth}) => ({user: auth.currentUser}))(({component: Component, user, ...rest }: any) => {
    if (user && user.organization && user.organization.owner === user.uuid && (!user.organization.email || !user.organization.phoneNumber)) {
        return (
            <Route {...rest} render={(props) => (
                <Redirect to={{
                    pathname: "/registrering/3",
                    state: { from: props.location }
                }} />
            )} />
        )
    }
    else if (user && user.status === "APPROVED") {
        return (
            <Route {...rest} render={(props) => (
                <Component {...props} />
            )} />
        )
    } else {
        return (
            <Route {...rest} render={(props) => (
                <Redirect to={{
                    pathname: getPathname(user),
                    state: { from: props.location }
                }} />
            )} />
        )
    }
});
