export default (state = {
    Companies: [],
    Employees: [],
    isLoading: false,
    Profile: {
        uuid: "",
        name: "",
        email: "",
        dateOfBirth: "",
        status: "INSUFFICIENT",
        permissions: [],
        organization: {
            name: "",
            identifier: "",
            address: "",
            email: "",
            phoneNumber: "",
            owner: "",
            uuid: ""
        }
    }
}, action) => {
    switch (action.type) {
        case "SET_ADMIN_IS_LOADING":
            return {
                ...state,
                isLoading: action.isLoading
            };
        case "SET_PROFILE":
            return {
                ...state,
                Profile: action.profile
            };
        case "SET_ORGANIZATION":
            return {
                ...state,
                Profile: {
                    ...state.Profile,
                    organization: action.organization
                }
            };
        case "SET_EMPLOYEES":
            return {
                ...state,
                Employees: action.employees
            };
        case "APPROVE_EMPLOYEE":
            return {
                ...state,
                Employees: state.Employees.map((employee) => {
                    if (employee.uuid === action.employee.uuid) {
                        return {
                            ...employee,
                            status: "APPROVED"
                        }
                    }
                    return employee
                })
            };
        case "REMOVE_EMPLOYEE":
            return {
                ...state,
                Employees: state.Employees.filter(
                    function(employee) {
                        return employee.uuid !== action.employee.uuid
                    }
                )
            };
        case "SET_COMPANIES":
            return {
                ...state,
                Companies: action.companies
            };
        default:
            return state;
    }
};
