// @flow strict
/* global API_URL DOMAIN */

import {push} from "connected-react-router";
import {default as Cookies, type CookieOptions} from "js-cookie";
import type {Dispatch} from "redux";

export const SET_CURRENT_USER = "@auth/SET_CURRENT_USER";
export const FETCH_CURRENT_USER = "FETCH_CURRENT_USER";
const FETCH_CURRENT_USER_FAILED = "@auth/FETCH_CURRENT_USER_FAILED";

export type User = {
    +name: ?string;
    +uuid: string;
    +email: ?string;
    +status: string;
    +permissions: ?string[];
    +organization: ?{
        +identifier: string;
        +name: ?string;
        +address: ?string;
        +phoneNumber: ?string;
        +email: ?string;
        +uuid: string;
    };
};

export type AuthState = {
    +currentUser: ?User,
    +error: ?string,
    +userPollTimeout: number
};

export type AuthAction =
    {type: "@auth/SET_CURRENT_USER", +user: ?User} |
    {type: "@auth/FETCH_CURRENT_USER_FAILED", +error: string}

export default (state: AuthState = {currentUser: null, error: null, userPollTimeout: 5000}, action: AuthAction) => {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                currentUser: action.user
            };
        case FETCH_CURRENT_USER_FAILED:
            return {
                ...state,
                error: action.error,
                userPollTimeout: state.userPollTimeout * 2
            };
        default:
            return state;
    }
};

export const setCurrentUser = (user: User) => (dispatch: Dispatch<AuthAction>) => new Promise<?User>((resolve) => {
    dispatch({type: SET_CURRENT_USER, user});
    if (user === null) {
        Cookies.remove("User");
    } else {
        const directives: CookieOptions = {expires: 7, secure: true};
        Cookies.set("User", user, Object.assign(directives, DOMAIN ? {domain: `.${DOMAIN}`} : {}));
    }
    resolve(user);
});

const onFetchUserFailed = (error: string) => (dispatch: Dispatch<any>, getState: () => any) =>
    new Promise<void>((resolve) => {
        dispatch({error, type: FETCH_CURRENT_USER_FAILED});
        setTimeout(() => dispatch(pollUserStatus()), getState().auth.userPollTimeout);
        resolve();
    });

const onFetchUserSuccess = (user: User) => (dispatch: Dispatch<any>, getState: () => any) =>
    new Promise<void>((resolve) => {
        dispatch(setCurrentUser(user));
        if (user.status === "APPROVED") {
            dispatch(push("/"));
        } else if (user.status === "INSUFFICIENT") {
            dispatch(push("/registrering"));
        } else if (user.status === "REJECTED") {
            dispatch(push("/registrering/2"));
        } else {
            setTimeout(() => dispatch(pollUserStatus()), getState().auth.userPollTimeout);
        }
        resolve();
    });

export const pollUserStatus = () => ({
    onFailure: onFetchUserFailed,
    onSuccess: onFetchUserSuccess,
    type: FETCH_CURRENT_USER,
    url: `${API_URL}/account/me`
});
