export default (state = {token: ""}, action) => {
    switch (action.type) {
        case "FETCHED_TOKEN":
            return {...state, token: action.token};
        case "FAILED_FETCH_TOKEN":
            return {...state, error: action.error};
        default:
            return state;
    }
};
