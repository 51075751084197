// @flow strict

import {injectGlobal} from "styled-components";

injectGlobal`
  body {
    margin: 0;
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100vw;
    height: 100vh;
    background: #eeeeee;
  }

  .loading-disabled {
    display: none;
  }

  .loading-spinner {
    width: 141px;
    height: 171px;
    animation: spin 4s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotateY(0deg); }
    100% { transform: rotateY(360deg);}
  }
`;
