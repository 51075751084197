// @flow strict

import {connectRouter, routerMiddleware} from "connected-react-router";
import {createBrowserHistory, createMemoryHistory} from "history";
import {applyMiddleware, combineReducers, createStore as createReduxStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";

import {fetchMiddleware, fetchReducer} from "./utils/fetch";
import {reducers} from "./reducers";
import {isBrowser, isProduction} from "./utils/env";

export const createStore = (route: string = "/") => {
    const enhancers = [];
    const history = isBrowser ? createBrowserHistory() : createMemoryHistory({initialEntries: [route]});
    const middlewares = [routerMiddleware(history), fetchMiddleware, thunk];

    return {
        history,
        store: createReduxStore(
            connectRouter(history)(combineReducers({...reducers, fetch: fetchReducer})),
            isBrowser && isProduction ? window.__PRELOADED_STATE__ : {},
            composeWithDevTools(applyMiddleware(...middlewares), ...enhancers)
        )
    };
};
