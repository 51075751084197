// @flow strict
/* global DOMAIN */
/* load global resources that are browser specific */

import "whatwg-fetch";
import "../resources/fonts.css";
import moment from "moment";
moment.locale("nb");

import {ConnectedRouter} from "connected-react-router";
import React from "react";
import {hydrate, render} from "react-dom";
import {Frontload} from "react-frontload";
import Loadable from "react-loadable";
import {Provider} from "react-redux";

import {isBrowser, isProduction} from "./utils/env";

if (DOMAIN && isBrowser) {
    document.domain = DOMAIN;
}

import {default as App} from "./component.root";
import {createStore} from "./store";

const rootNode = document.getElementById("react-root");

if (rootNode) {
    const {history, store} = createStore();
    const Root = (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Frontload noServerRender>
                    <App />
                </Frontload>
            </ConnectedRouter>
        </Provider>
    );

    if (isProduction) {
        Loadable.preloadReady().then(() => hydrate(Root, rootNode));
    } else {
        render(Root, rootNode);
    }
} else {
    // eslint-disable-next-line no-console
    console.error(
        "CRITICAL ERROR: Application root DOM node is missing." +
        "This is most likely caused by a misbehaving browser extension." +
        "Try disabling browser extensions and refreshing the page"
    );
}
