
export default (state = {
    deletingWellParkIsLoading: false,
    getWellParkIsLoading: false,
    getWellParksIsLoading: false,
    savingWellParkIsLoading: false,
    searchWellParkIsLoading: false,
    showSavedWellParkModal: false,
    uploadAttachmentIsLoading: false,
    wellPark: null,
    wellParks: []
}, action) => {
    switch (action.type) {
        case "WELL_PARK_ADD_ATTACHMENT":
            return {
                ...state,
                wellPark: {
                    ...state.wellPark,
                    attachments: state.wellPark.attachments.concat({})
                }
            };
        case "WELL_PARK_SET_ATTACHMENTS":
            return {
                ...state,
                wellPark: {
                    ...state.wellPark,
                    attachments: action.attachments
                }
            };
        case "WELL_PARK_SET_DELETING_WELL_PARK_IS_LOADING":
            return {
                ...state,
                deletingWellParkIsLoading: action.isLoading
            };
        case "WELL_PARK_SET_GET_WELL_PARK_IS_LOADING":
            return {
                ...state,
                getWellParkIsLoading: action.isLoading
            };
        case "WELL_PARK_SET_GET_WELL_PARKS_IS_LOADING":
            return {
                ...state,
                getWellParksIsLoading: action.isLoading
            };
        case "WELL_PARK_SET_SAVING_WELL_PARK_IS_LOADING":
            return {
                ...state,
                savingWellParkIsLoading: action.isLoading
            };
        case "WELL_PARK_SET_SEARCH_WELL_PARK_IS_LOADING":
            return {
                ...state,
                searchWellParkIsLoading: action.isLoading
            };
        case "WELL_PARK_SET_SHOW_SAVED_MODAL":
            return {
                ...state,
                showSavedWellParkModal: action.show
            };
        case "WELL_PARK_SET_UPLOAD_ATTACHMENT_IS_LOADING":
            return {
                ...state,
                uploadAttachmentIsLoading: action.isLoading
            };
        case "WELL_PARK_SET_WELL_PARK":
            return {
                ...state,
                wellPark: action.wellPark
            };
        case "WELL_PARK_SET_WELL_PARKS":
            return {
                ...state,
                wellParks: action.wellParks
            };
        default:
            return state;
    }
};
