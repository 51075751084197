export default (state = {
    announcement: null,
    announcementPeriod: null,
    error: null,
    information: null
}, action) => {
    switch (action.type) {
        case 'SET_ANNOUNCEMENT':
            return {
                ...state,
                announcement: action.announcement,
                announcementPeriod: action.announcementPeriod
            };
        case 'SET_ERROR':
            return {
                ...state,
                error: action.error
            };
        case 'SET_INFORMATION':
            return {
                ...state,
                information: action.information
            };
        case 'REMOVE_ANNOUNCEMENT':
            return {
                ...state,
                announcement: null,
                announcementPeriod: null
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                error: null
            };
        case 'REMOVE_INFORMATION':
            return {
                ...state,
                information: null
            };
        default:
            return state;
    }
};
