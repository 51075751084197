// @flow strict
import {default as active} from "./reducers/active";
import {default as admin} from  "./reducers/admin";
import {default as auth} from "./modules/auth/auth.redux";
import {default as errors} from "./reducers/errors";
import {default as help} from  "./reducers/help";
import {default as marker} from  "./reducers/marker";
import {default as mock} from  "./reducers/mock";
import {default as onboarding} from  "./reducers/onboarding";
import {default as published} from  "./reducers/published";
import {default as qualitycontrol} from  "./reducers/qualitycontrol";
import {default as routing} from  "./reducers/routing";
import {default as systemfeil} from  "./reducers/systemfeil";
import {default as token} from  "./reducers/token";
import {default as well} from  "./reducers/well";
import {default as well2} from  "./reducers/well2";
import {default as wellpark} from  "./reducers/wellpark";
import {default as wellSearch} from "./reducers/wellSearch";
import {default as configuration} from  "./reducers/configuration";

export const reducers = {
    admin,
    active,
    auth,
    errors,
    help,
    mock,
    marker,
    onboarding,
    published,
    qualitycontrol,
    token,
    systemfeil,
    routing,
    well,
    well2,
    wellpark,
    wellSearch,
    configuration
};
