
export default (state = {
    declineWellIsLoading: false,
    deleteWellIsLoading: false,
    publishWellIsLoading: false,
    saveWellIsLoading: false,
    showPublishedModal: false,
    validationErrors: null
}, action) => {
    switch (action.type) {
        case "WELL_SET_DECLINE_WELL_IS_LOADING":
            return {
                ...state,
                declineWellIsLoading: action.isLoading
            };
        case "WELL_SET_DELETE_WELL_IS_LOADING":
            return {
                ...state,
                deleteWellIsLoading: action.isLoading
            };
        case "WELL_SET_PUBLISH_WELL_IS_LOADING":
            return {
                ...state,
                publishWellIsLoading: action.isLoading
            };
        case "WELL_SET_SAVE_WELL_IS_LOADING":
            return {
                ...state,
                saveWellIsLoading: action.isLoading
            };
        case "WELL_SET_SHOW_PUBLISHED_MODAL":
            return {
                ...state,
                showPublishedModal: action.show
            };
        case "WELL_SET_VALIDATION_ERRORS":
            return {
                ...state,
                validationErrors: action.errors
            };
        default:
            return state;
    }
};
