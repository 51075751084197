export default (state = {
    map: {
        airphotoWMSURL: null,
        matrikkelenWMSURL: null,
        wmtsurl: null,
        granada: null
    }
}, action) => {
    switch (action.type) {
        case "GET_MAP_CONFIGURATION":
            return {
                ...state,
                map:{
                    ...action
                }
            };
        case "FAILED_GET_MAP_CONFIGURATION":
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
};
