import {background, loader, info} from "./LoadingScreen.css";
import PropTypes from "prop-types";
import React from "react";

export const LoadingScreen = (props) => {
    if (!props.show) {
        return null;
    }
    return (
        <div className={background}>
            <div className={loader}/>
            <div className={info}>
                {props.title}
            </div>
        </div>
    );
};

LoadingScreen.propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.string
};

LoadingScreen.defaultProps = {
    show: false,
    title: "Laster innhold...."
};

export default LoadingScreen;
