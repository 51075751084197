/* global module */
import React from "react";
import {Route, Switch} from "react-router";
import {hot} from "react-hot-loader";

/* load global resources that are environment agnostic */
import "./global.styles.js";

import {ProtectedRoute} from "./components/ProtectedRoute";
import {
    Admin,
    HelpPage,
    InProgress,
    Login,
    NewWell,
    OnBoarding,
    OnBoardingApproval,
    OnboardingContactInfo,
    OnboardingEpost,
    OnboardingName,
    PageNotFound,
    PublishedWells,
    QCWell,
    QualityControl,
    WellPark,
    WellParkNew,
    WellParkSearch,
    WellParkWells,
    WSWellList,
    WSWellSearch
} from "./components/Routes";

const Root = () => (
    <React.Fragment>
        <Switch>
            <ProtectedRoute
                component={NewWell}
                exact
                path={"/"}
            />
            <ProtectedRoute
                component={NewWell}
                exact
                path={"/brønn/opprett"}
            />
            <ProtectedRoute
                component={InProgress}
                exact
                path={"/brønn/pågående"}
            />
            <ProtectedRoute
                component={WSWellSearch}
                excat
                path={"/brønn/søk"}
            />
            <ProtectedRoute
                component={WSWellList}
                excat
                path={"/brønn/oversikt"}
            />
            <ProtectedRoute
                component={PublishedWells}
                exact
                path={"/brønn/publiserte"}
            />
            <ProtectedRoute
                component={WellParkSearch}
                exact
                path={"/brønnpark/søk"}
            />
            <ProtectedRoute
                component={WellPark}
                exact
                path={"/brønnpark"}
            />
            <ProtectedRoute
                component={WellParkNew}
                exact
                path={"/brønnpark/rediger"}
            />
            <ProtectedRoute
                component={WellParkNew}
                exact
                path={"/brønnpark/opprett"}
            />
            <ProtectedRoute
                component={WellParkWells}
                exact
                path={"/brønnpark/brønner"}
            />
            <ProtectedRoute
                component={Admin}
                exact
                path={"/admin"}
            />
            <ProtectedRoute
                component={QualityControl}
                exact
                path={"/kontroll"}
            />
            <ProtectedRoute
                component={QCWell}
                exact
                path={"/kontroll/brønn"}
            />
            <Route
                component={Login}
                exact
                path={"/inngang"}
            />
            <Route
                component={OnboardingName}
                exact
                path={"/registrering"}
            />
            <Route
                component={OnBoarding}
                exact
                path={"/registrering/2"}
            />
            <Route
                component={OnboardingContactInfo}
                exact
                path={"/registrering/3"}
            />
            <Route
                component={OnboardingEpost}
                exact
                path={"/registrering/4"}
            />
            <Route
                component={OnBoardingApproval}
                exact
                path={"/registrering/godkjenning"}
            />
            <ProtectedRoute
                component={HelpPage}
                exact
                path={"/hjelp"}
            />
            <Route
                component={PageNotFound}
                path={"*"}
            />
        </Switch>
    </React.Fragment>
);

export default hot(module)(Root);
