import orderBy from "lodash.orderby";

export default (state = {
    Filter: "",
    ApprovalList: [],
    PublishedList: [],
    message: "",
    error: "",
    markers: [],
    loadingPublishedList: false,
    loadingApprovalList: false
}, action) => {
    switch (action.type) {
        case "LOADING_PUBLISHED":
            return {
                ...state,
                loadingPublishedList: action.value
            };
        case "LOADING_APPROVAL":
            return {
                ...state,
                loadingApprovalList: action.value
            };
        case "GET_MAP_MARKERS":
            return {
                ...state,
                markers: [].concat(
                    state.ApprovalList.map((item) => [
                        item.location.coordinates.latitude,
                        item.location.coordinates.longitude] )
                ).concat(
                    state.PublishedList.map((item) => [
                        item.location.coordinates.latitude,
                        item.location.coordinates.longitude
                    ])
                )
            };
        case "GET_APPROVAL_WELLS_FROM_COORDS":
            return {
                ...state,
                ApprovalList: action.ApprovalWells,
            };
        case "GET_PUBLISHED_WELLS_FROM_COORDS":
            return {
                ...state,
                PublishedList: action.PublishedWells
            };
        case "FAILED_GET_APPROVAL_WELLS_FROM_COORDS":
            return {
                ...state,
                error: action.error
            };
        case "FAILED_GET_PUBLISHED_WELLS_FROM_COORDS":
            return {
                ...state,
                error: action.error
            };
        case "GET_APPROVAL_WELLS":
            return {
                ...state,
                loadingApprovalList: false,
                ApprovalList: action.ApprovalWells
            };
        case "FAILED_GET_APPROVAL_WELLS":
            return {
                ...state,
                loadingApprovalList: false,
                error: action.error
            };
        case "GET_PUBLISHED_WELLS":
            return {
                ...state,
                loadingPublishedList: false,
                PublishedList: action.PublishedWells
            };
        case "FAILED_GET_PUBLISHED_WELLS":
            return {
                ...state,
                loadingPublishedList: false,
                error: action.error
            };
        case "SET_PUBLISH_FILTER":
            return {...state,
                loadingPublishedList: false,
                Filter: action.Filter};
        case "FAILED_SET_PUBLISHED_FILTER":
            return {
                ...state,
                loadingPublishedList: false,
                error: action.error
            };
        case "SET_PUBLISHED_FILTER":
            return {
                ...state,
                loadingPublishedList: false,
                PublishedList: action.data
            };
        case "SET_APPROVAL_FILTER":
            return {
                ...state,
                loadingApprovalList: false,
                ApprovalList: action.data
            };
        case "FAILED_SET_APPROVAL_FILTER":
            return {
                ...state,
                loadingApprovalList: false,
                error: action.error
            };

        case "FAILED_GET_PUBLISHED_QUERY":
            return {
                ...state,
                loadingPublishedList: false,
                loading: false,
                error: action.error
            };
        case "GET_PUBLISHED_QUERY":
            return {
                ...state,
                loadingPublishedList: false,
                loadingApprovalList: false,
                loading: false,
                PublishedList: (action.well.status === "PUBLISHED" ? [].concat(action.well) : []),
                ApprovalList: (action.well.status === "APPROVAL" ? [].concat(action.well) : [])
            };
        case "SORT_APPROVAL_BY_VALUE":
            return {
                ...state,
                ApprovalList: JSON.stringify(
                    orderBy(state.ApprovalList, action.value, ["ASC"])) === JSON.stringify(state.ApprovalList)
                        ? state.ApprovalList.reverse()
                        : orderBy(state.ApprovalList, action.value, ["ASC"])
            };
        case "SORT_PUBLISHED_BY_VALUE":
            return {
                ...state,
                PublishedList: JSON.stringify(
                    orderBy(state.PublishedList, action.value, ["ASC"])) === JSON.stringify(state.PublishedList)
                        ? state.PublishedList.reverse()
                        : orderBy(state.PublishedList, action.value, ["ASC"])
            };
        default:
            return state;
    }
};

