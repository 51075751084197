
export default (state = {
    createdByFilter: null,
    filter: "ALL",
    listIsLoading: false,
    paginationCurrentLink: null,
    paginationLinks: {
        first: null,
        last: null,
        next: null,
        prev: null
    },
    publishWellsIsLoading: false,
    qualityInspectors: [],
    sortBy: "APPROVAL_REQUEST_DATE",
    sortOrder: "ASC",
    wells: []
}, action) => {
    switch (action.type) {
        case "QC_CLEAR_FILTERS":
            return {
                ...state,
                createdByFilter: null,
                sortBy: "APPROVAL_REQUEST_DATE",
                sortOrder: "ASC"
            };
        case "QC_SET_CREATED_BY_FILTER":
            return {
                ...state,
                createdByFilter: action.createdBy
            };
        case "QC_SET_FILTER":
            return {
                ...state,
                filter: action.filter
            };
        case "QC_SET_LIST_IS_LOADING":
            return {
                ...state,
                listIsLoading: action.isLoading
            };
        case 'QC_SET_PAGINATION_CURRENT_LINK':
            return {
                ...state,
                paginationCurrentLink: action.link
            };
        case 'QC_SET_PAGINATION_LINKS':
            return {
                ...state,
                paginationLinks: {
                    ...action.links
                }
            };
        case 'QC_SET_PUBLISH_WELLS_IS_LOADING':
            return {
                ...state,
                publishWellsIsLoading: action.isLoading
            };
        case "QC_SET_SORT":
            return {
                ...state,
                sortBy: action.sortBy,
                sortOrder: action.sortOrder
            };
        case 'QC_UPDATE_QUALITY_INSPECTORS':
            return {
                ...state,
                qualityInspectors: action.data
            };
        case "QC_UPDATE_WELLS":
            return {
                ...state,
                wells: action.data
            };
        default:
            return state;
    }
};
