export default (state = {markers: []}, action) => {
    switch (action.type) {
        case "SET_MAP_MARKER":
            return {
                ...state,
                markers: state.markers.concat({
                    id: action.id,
                    lat: action.lat,
                    lng: action.lng
                })
            };
        default:
            return state;
    }
};
