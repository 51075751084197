export default (state = {message: ""}, action) => {
    switch (action.type) {
        case "POST_MOCK":
            return {...state, message: action.message};
        case "FAILED_POST_MOCK":
            return {...state, error: action.error};
        default:
            return state;
    }
};
