
export default (state = {
    isLoading: false,
    well: null,
    wellList: null,
    WellSearchList: []
}, action) => {
    switch (action.type) {
        case "WELL_SEARCH_SET_IS_LOADING":
            return {
                ...state,
                isLoading: action.isLoading
            };
        case "WELL_SEARCH_SET_WELL":
            return {
                ...state,
                well: action.well
            };
        case "WELL_SEARCH_SET_WELL_LIST":
            return {
                ...state,
                wellList: action.wellList
            };
        case "WELL_SEARCH_CLEAR_WELL":
            return {
                ...state,
                well: null
            };
        case "WELL_SEARCH_CLEAR_WELL_SEARCH":
            return {
                ...state,
                well: null,
                wellList: null
            };
        case "WELL_SEARCH_SET_COMPANY_WELLS":
            return {
                ...state,
                WellSearchList: action.data
            };
        default:
            return state;
    }
};
