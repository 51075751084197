export default (state = {
    organizations: [{label: "Laster innhold....", value:  ""}],
    fullorgs: [],
    isLoading: false,
    relations: []
}, action) => {
    switch (action.type) {
        case "SET_ONBOARDING_IS_LOADING":
            return {
                ...state,
                isLoading: action.value
            };
        case "SET_ORGANIZATIONS":
            return {
                ...state,
                fullorgs: [].concat(action.organizations.map((item) => ({
                    identifier: item.identifier === null ? "" : item.identifier,
                    name: item.name === null ? "" : item.name,
                    address: item.address === null ? "" : item.address,
                    owner: item.owner === null ? "" : item.owner,
                    phoneNumber: item.phoneNumber === null ? "" : item.phoneNumber,
                    email: item.email === null ? "" : item.email,
                    uuid: item.uuid
                }))),
                isLoading: false,
                organizations: [{label: "Velg selskap", value:  ""}]
                    .concat(
                        action.organizations
                            .map((item) => ({label: item.name + " - (" + item.identifier + ")", value: item.identifier, uuid: item.uuid}))
                    )
            };
        case "SET_COMPANY_RELATIONS":
            return {
                ...state,
                relations: [].concat(action.relations)
            };
        default:
            return state;
    }
};
