import Loadable from "react-loadable";
import {LoadingScreen} from "./LoadingScreen";

export const Admin = Loadable({
    loader: () => import(/* webpackChunkName: "Admin", webpackPrefetch: true */ "./Admin/Admin"),
    loading: LoadingScreen,
});

export const Login = Loadable({
    loader: () => import(/* webpackChunkName: "Login", webpackPrefetch: true */ "./Login"),
    loading: LoadingScreen,
});

export const InProgress = Loadable({
    loader: () => import(/* webpackChunkName: "InProgress", webpackPrefetch: true */ "./InProgress/InProgress"),
    loading: LoadingScreen,
});

export const NewWell = Loadable({
    loader: () => import(/* webpackChunkName: "NewWell", webpackPrefetch: true */ "./Well/NewWell"),
    loading: LoadingScreen,
});

export const OnBoarding = Loadable({
    loader: () => import(/* webpackChunkName: "OnBoarding", webpackPrefetch: true */ "./Onboarding/OnBoarding"),
    loading: LoadingScreen,
});

export const OnBoardingApproval = Loadable({
    loader: () => import(/* webpackChunkName: "OnBoardingApproval", webpackPrefetch: true */ "./Onboarding/OnBoardingApproval"),
    loading: LoadingScreen
});

export const OnboardingContactInfo = Loadable({
    loader: () => import(/* webpackChunkName: "OnboardingContactInfo", webpackPrefetch: true */ "./Onboarding/OnboardingContactInfo"),
    loading: LoadingScreen,
});

export const OnboardingEpost = Loadable({
    loader: () => import(/* webpackChunkName: "OnboardingEpost", webpackPrefetch: true */ "./Onboarding/OnboardingEpost"),
    loading: LoadingScreen,
});

export const OnboardingName = Loadable({
    loader: () => import(/* webpackChunkName: "OnboardingName", webpackPrefetch: true */ "./Onboarding/OnboardingName"),
    loading: LoadingScreen,
});

export const PublishedWells = Loadable({
    loader: () => import(/* webpackChunkName: "PublishedWells", webpackPrefetch: true */ "./PublishedWells"),
    loading: LoadingScreen,
});

export const PageNotFound = Loadable({
    loader: () => import(/* webpackChunkName: "PageNotFound", webpackPrefetch: true */ "./PageNotFound"),
    loading: LoadingScreen,
});

export const HelpPage = Loadable({
    loader: () => import(/* webpackChunkName: "HelpPage", webpackPrefetch: true */ "./HelpPage"),
    loading: LoadingScreen,
});

export const WellPark = Loadable({
    loader: () => import(/* webpackChunkName: "WellPark", webpackPrefetch: true */ "./Collection/WellPark"),
    loading: LoadingScreen,
});

export const WellParkNew = Loadable({
    loader: () => import(/* webpackChunkName: "WellParkNew", webpackPrefetch: true */ "./Collection/WellParkNew"),
    loading: LoadingScreen,
});

export const WellParkWells = Loadable({
    loader: () => import(/* webpackChunkName: "WellParkWells", webpackPrefetch: true */ "./Collection/WellParkWells"),
    loading: LoadingScreen,
});

export const WellParkSearch = Loadable({
    loader: () => import(/* webpackChunkName: "WellParkSearch", webpackPrefetch: true */ "./Collection/WellParkSearch"),
    loading: LoadingScreen,
});

export const WSWellList = Loadable({
    loader: () => import(/* webpackChunkName: "WSWellList", webpackPrefetch: true */ "./WellSearch/WSWellList"),
    loading: LoadingScreen,
});

export const WSWellSearch = Loadable({
    loader: () => import(/* webpackChunkName: "WSWellSearch", webpackPrefetch: true */ "./WellSearch/WSWellSearch"),
    loading: LoadingScreen,
});

export const QCWell = Loadable({
    loader: () => import(/* webpackChunkName: "QCWell", webpackPrefetch: true */ "./Well/QCWell"),
    loading: LoadingScreen,
});

export const QualityControl = Loadable({
    loader: () => import(/* webpackChunkName: "QualityControl", webpackPrefetch: true */ "./QualityControl/QualityControl"),
    loading: LoadingScreen,
});
