// @flow strict
import {push} from "connected-react-router";
import {toggleUnauthorized} from "../actions/routing";

const STATUS_OK = 200;
const STATUS_MULTIPLE_CHOICES = 300;
const STATUS_UNAUTHORIZED = 401;
const STATUS_FORBIDDEN = 403;

type Action = {
    type: string,
    options?: {
        body?: any,
        headers?: {[key: string]: string},
        method?: string
    },
    filename?: string,
    queryParams: {[key: string]: string},
    onSuccess: (val: any) => any,
    onFailure: (val: any) => any,
    url: string
}

type ErrorWithResponse = {response?: Response} & Error

export const constructQueryUrl = (method: string, endpoint: string, parameters: {[key: string]: string}) => (
    method === "GET" && parameters
        ? `${endpoint}?${Object.entries(parameters)
            .map(([key, value]) => 
                `${encodeURIComponent(key)}=${typeof value === "string" ? encodeURIComponent(value) : ""}`)
            .join("&")}`
        : endpoint
);

const checkStatus = (response) => {
    if (response.status >= STATUS_OK && response.status < STATUS_MULTIPLE_CHOICES) {
        return response;
    }
    const error: ErrorWithResponse = new Error(response);
    error.response = response;
    throw error;
};

const parse = (accept) => (response) => (
    accept === "application/json"
        ? response.json()
        : response
);

const isFetchAction = ({type, onSuccess, onFailure, url}) =>
    typeof onSuccess === "function" && typeof onFailure === "function" &&
    typeof url === "string" && type.startsWith("FETCH_");

const isFetchFileAction = (type) => type.startsWith("FETCH_FILE_");

const getBody = ({headers, body}, isFileReq) => {
    if (isFileReq) {
        let data = new FormData();
        data.append("file", body);
        return data;
    }
    if ("Content-Type" in headers && headers["Content-Type"] === "application/json") {
        return JSON.stringify(body);
    }
    return body;
};

const applyVariableOptions = (options, type) => {
    const {headers, method} = options;
    if (method === "GET") {
        return options;
    }
    const isFileReq = isFetchFileAction(type);
    const modifiedOptions = options;
    if (!("Content-Type" in headers) && !isFileReq) {
        modifiedOptions.headers["Content-Type"] = "application/json";
    }

    modifiedOptions.body = getBody(modifiedOptions, isFileReq);
    return modifiedOptions;
};

const wrapFailure = (store) => (onFailure) => {
    return (error) => {
        if (error.response && error.response.status === STATUS_UNAUTHORIZED ||
            error.response.status === STATUS_FORBIDDEN
        ) {
            store.dispatch(toggleUnauthorized(true));
            store.dispatch(push("/inngang"));
        } else if (error.response) {
            store.dispatch(onFailure(error.response));
        } else if (typeof error === "object") {
            store.dispatch(onFailure(error));
        }
    };
};

const fetchReducer = (
    state: {[key: string]: any, token: string} = {token: ""},
    action: {type: "UPDATE_TOKEN", token: string}
) => {
    switch (action.type) {
        case "UPDATE_TOKEN":
            return {...state, token: action.token};
        default:
            return state;
    }
};

const applyDefaultOptions = (options = {}): any => ({
    ...options,
    credentials: options.credentials || "include",
    headers: options.headers ? options.headers.Accept ? options.headers
        : {Accept: "application/json", ...options.headers}
        : {Accept: "application/json"},
    method: options.method || "GET",
});

const fetchMiddleware = (store: any) => (next: any) => (action: Action) => {
    if (!isFetchAction(action)) {
        return next(action);
    }
    const {
        onFailure,
        onSuccess,
        queryParams,
        url
    } = action;

    const options = applyVariableOptions(applyDefaultOptions(action.options), action.type);

    if (isFetchFileAction(action.type) && options.method === "GET") {
        fetch(
            constructQueryUrl(options.method, url, queryParams),
            options
        )
            .then(checkStatus)
            .then(parse(options.headers.Accept))
            .then(response => response.blob())
            .then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.setAttribute("download", action.filename);
                a.setAttribute("target", "_blank");
                if (document.body) {
                    document.body.appendChild(a);
                }
                a.click();
                if (document.body) {
                    document.body.removeChild(a);
                }
                window.URL.revokeObjectURL(url);
            })
            .catch(wrapFailure(store)(onFailure));
    } else {
        fetch(
            constructQueryUrl(options.method, url, queryParams),
            options
        )
            .then(checkStatus)
            .then(parse(options.headers.Accept))
            .then((response) => store.dispatch(onSuccess(response)))
            .catch(wrapFailure(store)(onFailure));
    }

    return next(action);
};

export {fetchMiddleware, fetchReducer};
