export default (state = {
    message: "",
    error: ""
}, action) => {
    switch (action.type) {
        case "FAILED_RETRY":
            return {...state, error: action.error};
        case "RETRY":
            return {...state, message: action.message};
        default:
            return state;
    }
};
