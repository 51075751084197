
export default (state = {
    declinedWells: [],
    declinedWellsIsLoading: false,
    draftWells: [],
    draftWellsIsLoading: false
}, action) => {
    switch (action.type) {
        case "ACTIVE_SET_DECLINED_WELLS":
            return {
                ...state,
                declinedWells: action.wells
            };
        case "ACTIVE_SET_DECLINED_WELLS_IS_LOADING":
            return {
                ...state,
                declinedWellsIsLoading: action.isLoading
            };
        case "ACTIVE_SET_DRAFT_WELLS":
            return {
                ...state,
                draftWells: action.wells
            };
        case "ACTIVE_SET_DRAFT_WELLS_IS_LOADING":
            return {
                ...state,
                draftWellsIsLoading: action.isLoading
            };
        default:
            return state;
    }
};

/* */
