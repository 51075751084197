import moment from "moment";

export default (state = {
    IsPark: false,
    owner: "",
    savedPark: false,
    NGUSelectedCompany: "",
    NGUSelectedCompanyName: "",
    NGUSelectedCompanyUUID: null,
    Parks: [],
    Park: {
        requestId: "",
        id: "",
        serialNumber: 0,
        areaName: "",
        comment: "",
        wells: [],
        attachments: []
    },
    loading: false,
    Page: 1,
    toggleShowUsageHelp: false,
    zoomLevel: 4,
    DrillingType: {id: 50, code: "Loddrett" },
    MapCenter: [ "65.2063772", "15.7548828"],
    capacityDuration: {
        days: "",
        hours: "",
        mins: ""
    },
    Profile: {
        detailedSediment: false
    },
    CodeList: null,
    WellVersions: [],
    Well: {
        id: "",
        owner: "",
        collection: {id: "", serialNumber: 0, areaName: ""},
        serialNumber: "",
        reference: "",
        drillingRig: "",
        drillingDate: null,
        status: "DRAFT",
        attachments: [],
        assignee: "",
        assignees: [],
        requestId: "",
        lastModifiedDate: null,
        createdDate: null,
        employer: {
            name: "",
            phoneNumber: "",
            email: "",
            consultantReports: "",
            consultantCompanyName: ""
        },
        type: {
            id: 1,
            code: "Fjellbrønn"
        },
        application: {
            id: 0,
            code: ""
        },
        usage: {
            id: 0,
            code: ""
        },
        location: {
            coordinates: {
                latitude: "",
                longitude: ""
            },
            cadastralUnitNumber: "",
            propertyUnitNumber: "",
            leaseholdNumber: "",
            condominiumUnitNumber: "",
            municipality: "",
            positioningMethod: {
                id: 0,
                code: ""
            },
            streetName: "",
            county: "",
            elevationMeasuringMethod: {
                id: 0,
                code: ""
            },
            elevation: "",
            driller: ""
        },
        depthToBedrock: "",
        totalDepth: "",
        waterLevel: "",
        waterLevelMeasuringDate: null,
        overflowing: false,
        inclination: {
            deviation: "",
            direction: ""
        },
        boreholeDiameter: "",
        collector: {
            fluid: {id: 0, code: ""},
            type: {id: 0, code: ""}
        },
        comment: "",
        log: [
            {
                depthFrom: "",
                depthTo: "",
                hasBedrockCracks: false,
                bedrockSolidness: {
                    id: 0,
                    code: ""
                },
                bedrockType: {
                    id: 0,
                    code: ""
                },
                looseSedimentType: {
                    id: 0,
                    code: ""
                },
                sludgeColor: {
                    id: 0,
                    code: ""
                },
                waterInflow: {
                    id: 0,
                    code: ""
                },
                comment: ""
            }

        ],
        casing: {
            material: {
                id: 0,
                code: ""
            },
            length: "",
            diameter: "",
            elevation: "",
            elevationMeasuringMethod: {
                id: 0,
                code: ""
            }
        },
        filters: [],
        capacity: {
            initialCapacity: "",
            initialCapacityMeasuringMethod: {
                id: 0,
                code: ""
            },
            initialCapacityMeasuringDuration: "",
            increases: []
        },
        waterSample: {
            numberOfTestsTaken: "",
            recipient: ""
        }
    }
}, action) => {
    switch (action.type) {
        case "SET_IS_LOADING":
            return {
                ...state,
                loading: action.value,
                title: action.title
            };
        case "SELECT_WELL_TYPE":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    type: action.typeofwell
                }
            };
        case "SELECT_USAGEAREA_TYPE":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    application: action.application
                }
            };
        case "SELECT_USAGECODE_TYPE":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    usage: action.usage

                }
            };
        case "SELECT_DRILLDATE_TYPE":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    drillingDate: action.DrillDate,
                }
            };
        case "SELECT_INTERNALID_TYPE":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    reference: action.InternalId
                }
            };
        case "SELECT_DRILL_TEAM_TYPE":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    drillingRig: action.DrillTeam
                }
            };
        case "SELECT_OWNER_NAME_TYPE":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    employer: {
                        ...state.Well.employer,
                        name: action.Name
                    }
                }
            };
        case "SELECT_OWNER_PHONENUMBER_TYPE":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    employer: {
                        ...state.Well.employer,
                        phoneNumber: action.PhoneNumber
                    }
                }
            };
        case "SELECT_OWNER_EMAIL_TYPE":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    employer: {
                        ...state.Well.employer,
                        email: action.Email
                    }
                }
            };
        case "SELECT_OWNER_REPORTNUMBER_TYPE":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    employer: {
                        ...state.Well.employer,
                        consultantCompanyName: action.Firm,
                        consultantReports: action.ReportNumber
                    }
                }
            };
        case "SELECT_LOCATION_COORDS_TYPE":
            return {
                ...state,
                MapCenter: [action.lat, action.lng],
                zoomLevel: 16,
                Well: {
                    ...state.Well,
                    location: {
                        ...state.Well.location,
                        coordinates: {
                            latitude: action.lat,
                            longitude: action.lng
                        }
                    }
                }
            };
        case "SELECT_LOCATION_POSITIONMETHOD_TYPE":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    location: {
                        ...state.Well.location,
                        positioningMethod: action.Method
                    }
                }
            };
        case "SELECT_LOCATION_MATRIX_TYPE":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    location: {
                        ...state.Well.location,
                        streetName: action.Matrix.streetName,
                        leaseholdNumber: action.Matrix.leaseholdNumber,
                        municipality: action.Matrix.municipality,
                        cadastralUnitNumber: action.Matrix.cadastralUnitNumber,
                        propertyUnitNumber: action.Matrix.propertyUnitNumber,
                        condominiumUnitNumber: action.Matrix.condominiumUnitNumber,
                        county: action.Matrix.county
                    }
                }
            };
        case "SELECT_LOCATION_ELEVATIONMETHOD_TYPE":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    location: {
                        ...state.Well.location,
                        elevationMeasuringMethod: action.ElevationMethod
                    }
                }
            };
        case "FETCHED_WELL_VERSIONS_FAILED":
            return {
                ...state,
                WellVersions:[]
            };
        case "FETCHED_WELL_VERSIONS_SUCCESSFULLY":
            return {
                ...state,
                WellVersions: action.data
            };
        case "SELECT_LOCATION_ELEVATION_TYPE":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    location: {
                        ...state.Well.location,
                        elevation: action.Elevation
                    }
                }
            };
        case "GET_CODELIST":
            return {
                ...state,
                loading: false,
                CodeList: action.CodeList
            };
        case "FAILED_GET_CODELIST":
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case "GET_ADDRESS":
            return  {
                ...state,
                loading: false,
                zoomLevel: 18,
                Well: {
                    ...state.Well,
                    location: {
                        ...state.Well.location,
                        cadastralUnitNumber: action.StedMatrikkel.cadastralUnitNumber === "0" ? "" : action.StedMatrikkel.cadastralUnitNumber,
                        propertyUnitNumber: action.StedMatrikkel.propertyUnitNumber === "0" ? "" : action.StedMatrikkel.propertyUnitNumber,
                        leaseholdNumber: action.StedMatrikkel.leaseholdNumber === "0" ? "" : action.StedMatrikkel.leaseholdNumber,
                        county: action.StedMatrikkel.county === null ? "" : action.StedMatrikkel.county,
                        condominiumUnitNumber: action.StedMatrikkel.condominiumUnitNumber === "0" ? "" : action.StedMatrikkel.condominiumUnitNumber,
                        municipality: action.StedMatrikkel.municipality === null ? "" : action.StedMatrikkel.municipality,
                        streetName: action.StedMatrikkel.address === null ? "" : action.StedMatrikkel.address
                    }
                }
            };
        case "FAILED_GET_ADDRESS":
            return {...state,
                loading: false,
                error: action.error};
        case "SELECT_WELLINFO_DEPTH":
            return {...state,
                Well: {
                    ...state.Well,
                    totalDepth: action.WellDepth.TotalDepth,
                    depthToBedrock: action.WellDepth.DepthToBedrock
                    }
                };
        case "SELECT_WELL_CASING":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    casing: action.casing
                }
            };
        case "SET_ENERGY_INFO":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    collector: {
                        ...state.Well.collector,
                        fluid: action.item.fluid,
                        type: action.item.type
                    }
                }
            };
        case "SET_WATER_TEST":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    waterSample: {
                        ...state.Well.waterSample,
                        numberOfTestsTaken: action.item.numberOfTestsTaken,
                        recipient: action.item.recipient
                    }
                }
            };
        case "SET_FINAL_COMMENT":
        return {
            ...state,
            Well: {
                ...state.Well,
                    comment: action.text
                }
        };
        case "SELECT_WELL_INFO":
            return {
                ...state,
                DrillingType: action.info.DrillingType,
                Well: {
                    ...state.Well,
                    waterLevel: action.info.waterLevel,
                    overflowing: action.info.overflowing,
                    boreholeDiameter: action.info.boreholeDiameter,
                    inclination: {
                        ...state.Well.inclination,
                        direction: action.info.direction,
                        deviation: action.info.deviation
                    }
                }
            };
        case "SELECT_REMOVE_DRILL_LOG_DETAILS":
            return {
                ...state,
                Well: {
                    ...state.Well,
                        log: [
                            ...state.Well.log.slice(0, action.index),
                            ...state.Well.log.slice(action.index + 1)
                        ]
                }
            };
        case "SELECT_ADD_DRILL_LOG_DETAIL":
            return {
                ...state,
                Well: {
                    ...state.Well,
                        log: [...state.Well.log].concat(action.item)
                }
            };
        case "SELECT_ADD_FILTER":
            return {
                ...state,
                Well: {
                    ...state.Well,
                        filters: [...state.Well.filters].concat(action.item)
                }
            };

        case "SELECT_DELETE_FILTER":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    filters: [
                        ...state.Well.filters.slice(0, action.index),
                        ...state.Well.filters.slice(action.index + 1)
                    ]
                }
            };
        case "ADD_CAPACITY_INCREASE":
            return {
                ...state,
                Well: {
                ...state.Well,
                    capacity: {
                    ...state.Well.capacity,
                        increases: [...state.Well.capacity.increases].concat(action.item)
                    }
                }
            };
        case "DELETE_CAPACITY_INCREASE":
            return {
                ...state,
                Well: {
                ...state.Well,
                    capacity: {
                    ...state.Well.capacity,
                        increases: [
                            ...state.Well.capacity.increases.slice(0, action.index),
                            ...state.Well.capacity.increases.slice(action.index + 1)
                        ]

                    }
                }
            };
        case "ADD_MANSJETT":
            return {
                ...state,
                Well: {
                ...state.Well,
                    capacity: {
                    ...state.Well.capacity,
                        increases: [
                        ...state.Well.capacity.increases.slice(0, action.index),
                            {
                                ...state.Well.capacity.increases[action.index],
                                pressurizations: [...state.Well.capacity.increases[action.index].pressurizations].concat(action.item)

                            },
                        ...state.Well.capacity.increases.slice(action.index+1)]
                        }
                    }
                };
        case "DELETE_MANSJETT":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    capacity: {
                        ...state.Well.capacity,
                        increases: [
                        ...state.Well.capacity.increases.slice(0, action.capacityindex),
                            {
                                ...state.Well.capacity.increases[action.capacityindex],
                                pressurizations: [
                                    ...state.Well.capacity.increases[action.capacityindex].pressurizations.slice(0, action.mansjettindex),
                                    ...state.Well.capacity.increases[action.capacityindex].pressurizations.slice(action.mansjettindex+1)
                                ]
                            },
                        ...state.Well.capacity.increases.slice(action.capacityindex+1)]
                    }

                }
            };
        case "SET_CAPACITY_DATE":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    capacity: {
                        ...state.Well.capacity,
                        increases: [
                            ...state.Well.capacity.increases.slice(0, action.index),
                            {
                                ...state.Well.capacity.increases[action.index],
                                performedDate: action.date
                            },
                            ...state.Well.capacity.increases.slice(action.index + 1)
                        ]
                    }
                }
            };
        case "UPDATE_MANSJETT_INFO":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    capacity: {
                        ...state.Well.capacity,
                        increases: [
                            ...state.Well.capacity.increases.slice(0, action.capacityindex),
                            {
                                ...state.Well.capacity.increases[action.capacityindex],
                                pressurizations: [
                                    ...state.Well.capacity.increases[action.capacityindex].pressurizations.slice(0, action.mansjettindex),
                                    {
                                        depthFrom: action.info.depthFrom,
                                        depthTo: action.info.depthTo,
                                        maximumPressure: action.info.maximumPressure,
                                        minimumPressure: action.info.minimumPressure
                                    },
                                    ...state.Well.capacity.increases[action.capacityindex].pressurizations.slice(action.mansjettindex+1)
                                ]
                            },
                            ...state.Well.capacity.increases.slice(action.capacityindex + 1)
                        ]
                    }
                }
            };
        case "UPDATE_CAPACITY_INCREASE":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    capacity: {
                      ...state.Well.capacity,
                        increases: [
                        ...state.Well.capacity.increases.slice(0, action.index),
                        {
                            ...state.Well.capacity.increases[action.index],
                            performedByOrganization: action.info.performedByOrganization,
                            performedByOrganizationAddress: action.info.performedByOrganizationAddress,
                            capacity: action.info.capacity,
                            capacityMeasuringMethod: action.info.capacityMeasuringMethod,
                            waterLevel: action.info.waterLevel
                        },
                        ...state.Well.capacity.increases.slice(action.index + 1)
                        ]
                    }

                }
            };
        case "UPDATE_CAPACITY_INCREASE_METHOD":
            return {
                ...state,
                Well: {
                  ...state.Well,
                    capacity: {
                    ...state.Well.capacity,
                        increases: [
                        ...state.Well.capacity.increases.slice(0, action.index),
                        {
                            ...state.Well.capacity.increases[action.index],
                            capacityIncreaseMethod: action.value
                        },
                        ...state.Well.capacity.increases.slice(action.index + 1)
                        ]
                    }
                }

            };
        case "FAILED_GET_WELL":
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case "GET_WELL":
            return {
                ...state,
                loading: false,
                error: null,
                IsPark: action.item.collection === null ? false : action.item.collection.id !== "",
                zoomLevel: action.item.location.coordinates === null ? 4 : action.item.location.coordinates.latitude === 0 ? 4 : 18,
                MapCenter:action.item.location.coordinates !== null ?
                    action.item.location.coordinates.latitude > 0 ? [action.item.location.coordinates.latitude.toString(), action.item.location.coordinates.longitude.toString()]
                    : [ "65.2063772", "15.7548828"] : [ "65.2063772", "15.7548828"],
                capacityDuration: {
                    days: Math.floor(action.item.capacity.initialCapacityMeasuringDuration / 86400).toString(),
                    hours: Math.floor((action.item.capacity.initialCapacityMeasuringDuration % 86400) / 3600).toString(),
                    mins:  Math.floor(((action.item.capacity.initialCapacityMeasuringDuration % 86400) % 3600) / 60).toString()
                },
                Page: 10,
                DrillingType: action.item.inclination === null ? {id: 50, code: "Loddrett"} :
                    action.item.inclination.deviation === 0 || action.item.inclination.deviation === null ? {id: 50, code: "Loddrett"} :
                        {id: 51, code: "Skrå"}
                ,
                Well: {
                    id: action.item.id,
                    owner: action.item.owner,
                    collection: action.item.collection === null ? {id: "", serialNumber: 0, areaName: ""} : action.item.collection,
                    reference: action.item.reference === null ? "" : action.item.reference,
                    serialNumber: action.item.serialNumber === 0 ? "" : action.item.serialNumber.toString(),
                    drillingRig: action.item.drillingRig === null ? "" : action.item.drillingRig,
                    drillingDate: action.item.drillingDate === null ? null : moment(action.item.drillingDate),
                    lastModifiedDate: action.item.lastModifiedDate === null ? null : moment(action.item.lastModifiedDate),
                    createdDate: action.item.createdDate === null ? null : moment(action.item.createdDate),
                    assignee: action.item.assignee === null ? "" : action.item.assignee,
                    assignees: action.item.assignees === null ? [] : action.item.assignees,
                    requestId: action.item.requestId,
                    status: action.item.status === null ? "DRAFT" : action.item.status,
                    declineNote: action.item.declineNote,
                    links: action.item.links,
                    meta: action.item.meta,
                    employer: {
                        name: action.item.employer.name === null ? "" : action.item.employer.name,
                        phoneNumber: action.item.employer.phoneNumber === null ? "" : action.item.employer.phoneNumber,
                        email: action.item.employer.email === null ? "" : action.item.employer.email,
                        consultantReports: action.item.employer.consultantReports === null ? "" : action.item.employer.consultantReports,
                        consultantCompanyName: action.item.employer.consultantCompanyName === null ? "" : action.item.employer.consultantCompanyName
                    },
                    type: action.item.type === null ? {
                        id: 0,
                        code: ""
                    }    : action.item.type,
                    application: action.item.application === null ?
                        {
                            id: 0,
                            code: ""
                        }    : action.item.application,
                    usage: action.item.usage === null ? {
                        id: 0,
                        code: ""
                        } : action.item.usage,
                    location: {
                        coordinates: action.item.location.coordinates === null ? {latitude: "", longitude: ""} : {
                            latitude: action.item.location.coordinates.latitude.toString(),
                            longitude: action.item.location.coordinates.longitude.toString()
                        },
                        streetName: action.item.location.streetName === null ? "" : action.item.location.streetName,
                        cadastralUnitNumber: action.item.location.cadastralUnitNumber === null? "" : action.item.location.cadastralUnitNumber,
                        propertyUnitNumber: action.item.location.propertyUnitNumber === null? "" : action.item.location.propertyUnitNumber,
                        leaseholdNumber: action.item.location.leaseholdNumber === null? "" : action.item.location.leaseholdNumber,
                        condominiumUnitNumber: action.item.location.condominiumUnitNumber === null? "" : action.item.location.condominiumUnitNumber,
                        municipality: action.item.location.municipality === null? "" : action.item.location.municipality,
                        positioningMethod: action.item.location.positioningMethod === null? {id: 0, code: ""} : action.item.location.positioningMethod,
                        performedByOrganizationAddress: action.item.location.performedByOrganizationAddress === null? "" : action.item.location.performedByOrganizationAddress,
                        county: action.item.location.county === null? "" : action.item.location.county,
                        elevationMeasuringMethod: action.item.location.elevationMeasuringMethod === null? {id: 0, code: ""} : action.item.location.elevationMeasuringMethod,
                        elevation: action.item.location.elevation === null ? "" : action.item.location.elevation.toString(),
                        driller: action.item.location.driller === null? "" : action.item.location.driller
                    },
                    depthToBedrock: action.item.depthToBedrock === null ? "" : action.item.depthToBedrock.toString(),
                    totalDepth: action.item.totalDepth === null ? "" :  action.item.totalDepth === 0 ? "" : action.item.totalDepth.toString(),
                    waterLevel: action.item.waterLevel === null ? "" :  action.item.waterLevel  === 0 ? "" : action.item.waterLevel.toString(),
                    waterLevelMeasuringDate: action.item.waterLevelMeasuringDate === null ? null : moment(action.item.waterLevelMeasuringDate),
                    overflowing: action.item.overflowing === null ? false : action.item.overflowing,
                    inclination: action.item.inclination === null ? {
                            deviation: "",
                            direction: ""
                        } : {
                            deviation: action.item.inclination.deviation === null ? "" : action.item.inclination.deviation === 0 ? "" : action.item.inclination.deviation.toString(),
                            direction: action.item.inclination.direction === null ? "" : action.item.inclination.direction === 0 ? "" :action.item.inclination.direction.toString()
                        },
                    boreholeDiameter: action.item.boreholeDiameter === null ? "" : action.item.boreholeDiameter === 0  ? "" : action.item.boreholeDiameter.toString(),
                    collector: action.item.collector === null ? {
                            fluid: {id: 0, code: ""},
                            type: {id: 0, code: ""}
                        } : {
                            fluid: action.item.collector.fluid === null ? {id: 0, code: ""} : action.item.collector.fluid,
                            type: action.item.collector.type === null ? {id: 0, code: ""} : action.item.collector.type
                        },
                    attachments: action.item.attachments === null ? [] : action.item.attachments,
                    comment: action.item.comment === null ? "" : action.item.comment,
                    log: action.item.log === null  || action.item.log.length === 0 ? [] :
                         [].concat(action.item.log.map((item) => {
                             return ({
                                 bedrockSolidness: item.bedrockSolidness === null ? {id: 0, code: ""} : item.bedrockSolidness,
                                 bedrockType: item.bedrockType === null ? {id: 0, code: ""} : item.bedrockType,
                                 comment: item.comment === null ? "" : item.comment,
                                 depthFrom: item.depthFrom === null ? "" : item.depthFrom.toString(),
                                 depthTo: item.depthTo === null ? "" : item.depthTo === 0 ? "" : item.depthTo.toString(),
                                 hasBedrockCracks: item.hasBedrockCracks,
                                 looseSedimentType: item.looseSedimentType === null ? {id: 0, code: ""} : item.looseSedimentType,
                                 sludgeColor: item.sludgeColor === null ? {id: 0, code: ""} : item.sludgeColor,
                                 waterInflow: item.waterInflow === null ? {id: 0, code: ""} : item.waterInflow
                             })
                             })),
                    casing: action.item.casing === null ? {
                            material: {id: 0, code: ""},
                            length: "",
                            diameter: "",
                            elevation: "",
                            elevationMeasuringMethod: {id: 0, code: ""}
                        } : {
                            material: action.item.casing.material === null ? {id: 0, code: ""} : action.item.casing.material,
                            length: action.item.casing.length === null  ? "" : action.item.casing.length === 0 ? "" : action.item.casing.length.toString(),
                            diameter: action.item.casing.diameter === null ? "" :  action.item.casing.diameter === 0 ? "" : action.item.casing.diameter.toString(),
                            elevation: action.item.casing.elevation === null  ? "" : action.item.casing.elevation.toString(),
                            elevationMeasuringMethod: action.item.casing.elevationMeasuringMethod === null ? {id: 0, code: ""} : action.item.casing.elevationMeasuringMethod
                        },
                    filters: action.item.filters === null? [] : [].concat(action.item.filters.map((item) =>{
                        return ({
                                depthFrom: item.depthFrom === null ? "" : item.depthFrom.toString(),
                                depthTo: item.depthTo === null ? "" :  item.depthTo === 0 ? "" : item.depthTo.toString(),
                                aperture: item.aperture === null ? "" : item.aperture === 0 ? "" : item.aperture.toString(),
                                diameter: item.diameter === null ? "" :  item.diameter === 0 ? "" : item.diameter.toString(),
                                material: item.material === null ? {id: 0, code: ""} :
                                    item.material,
                                type: item.type === null ? {id: 0, code: ""} :
                                    item.type
                            }
                        )} )),
                    capacity: action.item.capacity === null ? {
                            initialCapacity: "",
                            initialCapacityMeasuringMethod: {id: 0, code: ""},
                            initialCapacityMeasuringDuration: "",
                            increases: []
                        } : {
                            initialCapacity: action.item.capacity.initialCapacity === null ? "" : action.item.capacity.initialCapacity.toString(),
                            initialCapacityMeasuringMethod: action.item.capacity.initialCapacityMeasuringMethod === null ? {id: 0, code: ""} : action.item.capacity.initialCapacityMeasuringMethod,
                            initialCapacityMeasuringDuration: action.item.capacity.initialCapacityMeasuringDuration === null ? "" : action.item.capacity.initialCapacityMeasuringDuration === 0 ? "" :  action.item.capacity.initialCapacityMeasuringDuration.toString(),
                            increases: action.item.capacity.increases === null || action.item.capacity.increases.length === 0 ? [] :
                                [].concat(action.item.capacity.increases.map((item) => {
                                    return ({
                                        capacityIncreaseMethod: item.capacityIncreaseMethod,
                                        performedByOrganization: item.performedByOrganization,
                                        performedByOrganizationAddress: item.performedByOrganizationAddress,
                                        performedDate: item.performedDate === null ? null : moment(item.performedDate),
                                        capacity: item.capacity === null ? "" : item.capacity === 0 ? "" : item.capacity.toString(),
                                        capacityMeasuringMethod: item.capacityMeasuringMethod === null ? {id: 0, code: ""} : item.capacityMeasuringMethod,
                                        capacityMeasuringDuration: item.capacityMeasuringDuration,
                                        waterLevel: item.waterLevel === null ?  "" : item.waterLevel === 0 ? "" : item.waterLevel.toString(),
                                        waterLevelMeasuringDate: moment(item.waterLevelMeasuringDate),
                                        pressurizations: item.pressurizations === null || item.pressurizations === 0 ? [] :
                                            [].concat(item.pressurizations.map((press) => {
                                                return ({
                                                    depthFrom: press.depthFrom === null ? "" : press.depthFrom.toString(),
                                                    depthTo: press.depthTo === null ? "" :  press.depthTo === 0 ? "" : press.depthTo.toString(),
                                                    maximumPressure: press.maximumPressure === null ? "" :  press.maximumPressure === 0 ? "" :  press.maximumPressure.toString(),
                                                    minimumPressure: press.minimumPressure === null ? "" : press.minimumPressure.toString()
                                                })
                                            }))
                                    })
                                }))
                    },
                    waterSample: action.item.waterSample === null ? {
                            numberOfTestsTaken: "",
                            recipient: ""
                        } : {
                            numberOfTestsTaken: action.item.waterSample.numberOfTestsTaken === null ? "" : action.item.waterSample.numberOfTestsTaken.toString(),
                            recipient: action.item.waterSample.recipient === null ? "" : action.item.waterSample.recipient
                        }
            }};
        case "PUT_WELL":
            return {
                ...state,
                loading: false,
                error: null,
                Well: {
                    ...state.Well,
                    id: action.data.id,
                    owner: action.data.owner,
                    serialNumber: action.data.serialNumber.toString(),
                    requestId: action.data.requestId,
                    links: action.data.links
                }};
        case "TOGGLE_DETAILED_SEDIMENT":
            return {...state,
                Profile: {
                    ...state.Profile,
                    detailedSediment: !state.Profile.detailedSediment
                }};
        case "SELECT_INFO_DATE":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    waterLevelMeasuringDate: action.date
                }
            };
        case "GOTO_NEXT_PAGE":
            return {
                ...state,
                Page: state.Page +1
            };
        case "GOTO_PREVIOUS_PAGE":
            return {
                ...state,
                Page: state.Page -1
            };
        case "GOTO_PAGE":
            return {
                ...state,
                Page: action.page
            };
        case "UPDATE_DRILL_LOG_DETAIL":
            return {...state,
            Well: {
                ...state.Well,
                log: [
                    ...state.Well.log.slice(0, action.index),
                    {
                        detailedSediment: action.item.detailedSediment,
                        depthFrom: action.item.depthFrom,
                        depthTo: action.item.depthTo,
                        hasBedrockCracks: action.item.hasBedrockCracks,
                        bedrockSolidness: action.item.bedrockSolidness,
                        bedrockType: action.item.bedrockType,
                        looseSedimentType: action.item.looseSedimentType,
                        sludgeColor: action.item.sludgeColor,
                        waterInflow: action.item.waterInflow,
                        comment: action.item.comment
                    },
                    ...state.Well.log.slice(action.index + 1)
                ]
            }};
        case "WELL_SET_FILTER":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    filters: [
                        ...state.Well.filters.slice(0, action.index),
                        {
                            depthFrom: action.Filter.depthFrom,
                            depthTo: action.Filter.depthTo,
                            diameter: action.Filter.diameter,
                            aperture: action.Filter.aperture,
                            type: action.Filter.type,
                            material: action.Filter.material
                        },
                        ...state.Well.filters.slice(action.index + 1)
                    ]
                }
            };
        case "SET_SLANT_INFO":
            return {
                ...state,
                DrillingType: action.item.DrillingType,
                Well: {
                    ...state.Well,
                    inclination:
                    {
                    ...state.Well.inclination,
                            deviation: action.item.deviation,
                            direction: action.item.direction
                    }
                }

            };
        case "RESET_FORM":
            return {
                ...state,
                IsPark: false,
                loading: false,
                error: null,
                NGUSelectedCompany: "",
                NGUSelectedCompanyName: "",
                NGUSelectedCompanyUUID: null,
                WellVersions: [],
                Page: 1,
                DrillingType: {id: 50, code: "Loddrett"},
                zoomLevel: 4,
                capacityDuration: {
                    days: "0",
                    hours: "0",
                    mins: "0"
                },
                Well: {
                    id: "",
                    collection: {id: "", serialNumber: 0, areaName: "" },
                    serialNumber: "",
                        owner: "",
                    reference: "",
                    drillingRig: "",
                    drillingDate: null,
                    status: "DRAFT",
                    attachments: [],
                    assignee: "",
                    assignees: [],
                    requestId: "",
                    createdDate: null,
                    lastModifiedDate: null,
                    employer: {
                        name: "",
                        phoneNumber: "",
                        email: "",
                        consultantReports: "",
                        consultantCompanyName: ""
                    },
                    type: {id: 1, code: "Fjellbrønn"},
                    application: {id: 0, code: ""},
                    usage: {id: 0, code: ""},
                    location: {
                        coordinates: {
                            latitude: "",
                            longitude: "",
                        },
                        streetName: "",
                        cadastralUnitNumber: "",
                        propertyUnitNumber: "",
                        leaseholdNumber: "",
                        condominiumUnitNumber: "",
                        municipality: "",
                        positioningMethod: {id: 0, code: ""},
                        performedByOrganizationAddress: "",
                        county: "",
                        elevationMeasuringMethod: {id: 0, code: ""},
                        elevation: "",
                        driller: ""
                    },
                    depthToBedrock: "",
                    totalDepth: "",
                    waterLevel: "",
                    waterLevelMeasuringDate: null,
                    overflowing: false,
                    inclination: {
                        deviation: "",
                        direction: ""
                    },
                    boreholeDiameter: "",
                    collector: {
                        fluid: {id: 0, code: ""},
                        type: {id: 0, code: ""}
                    },
                    comment: "",
                    log: [
                        {
                            depthFrom: "",
                            depthTo: "",
                            hasBedrockCracks: false,
                            bedrockSolidness: {id: 0, code: ""},
                            bedrockType: {id: 0, code: ""},
                            looseSedimentType: {id: 0, code: ""},
                            sludgeColor: {id: 0, code: ""},
                            waterInflow: {id: 0, code: ""},
                            comment: ""
                        }

                    ],
                    casing: {
                        material: {id: 0, code: ""},
                        length: "",
                        diameter: "",
                        elevation: "",
                        elevationMeasuringMethod: {id: 0, code: ""}
                    },
                    filters: [],
                    capacity: {
                        initialCapacity: "",
                        initialCapacityMeasuringMethod: {id: 0, code: ""},
                        initialCapacityMeasuringDuration: "",
                        increases: []
                    },
                    waterSample: {
                        numberOfTestsTaken: "",
                        recipient: ""
                    }

                }
        };
        case "SET_CAPACITY_INFO":
            return {
                ...state,
                capacityDuration: action.info.capacityDuration,
                Well: {
                    ...state.Well,
                    capacity: {
                        ...state.Well.capacity,
                        initialCapacity: action.info.initialCapacity,
                        initialCapacityMeasuringMethod: action.info.initialCapacityMeasuringMethod,
                        initialCapacityMeasuringDuration: action.info.initialCapacityMeasuringDuration
                    }
                }
            };
        case "ADD_UPLOAD":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    attachments: [
                        ...state.Well.attachments.slice(0, action.index),
                        {
                            name: action.filename,
                            type: action.filetype,
                            url: state.Well.attachments[action.index].url
                        },
                        ...state.Well.attachments.slice(action.index +1)
                    ]
                }
            };
        case "DELETE_UPLOAD":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    attachments: [
                        ...state.Well.attachments.slice(0, action.index),
                        ...state.Well.attachments.slice(action.index +1)
                    ]
                }
            };
        case "ADD_FILE":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    attachments: state.Well.attachments.concat(action.file)
                }
            };
        case "SET_MAP_CENTER_COORDS":
            return {
                ...state,
                MapCenter: action.coords[0] === "" || action.coords[1] === "" ? state.MapCenter : action.coords
            };
        case "FAILED_DOWNLOAD_PDF":
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case "GET_ALTITUDE":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    location: {
                        ...state.Well.location,
                        elevation: action.data.elevation
                    }
                }
            };
        case "FAILED_UPLOAD_WELL_ATTACHMENT":
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case "FAILED_DELETE_FILE_FROM_API":
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case "SET_INCREASE_DURATION":
            return {
                ...state,
              Well: {
                  ...state.Well,
                  capacity: {
                      ...state.Well.capacity,
                      increases: [...state.Well.capacity.increases.slice(0, action.index),
                                    {
                                        ...state.Well.capacity.increases[action.index],
                                                capacityMeasuringDuration: Number(action.duration)
                                    },
                                    ...state.Well.capacity.increases.slice(action.index +1)]
                  }
              }
            };
        case "SET_ZOOM_LEVEL":
            return {
                ...state,
                zoomLevel: action.level
            };
        case "TOGGLE_SHOW_USAGE_HELP":
            return {
                ...state,
                toggleShowUsageHelp: !state.toggleShowUsageHelp
            };
        case "SET_COLLECTION_ID":
            return {
                ...state,
                IsPark: true,
                Well: {
                    ...state.Well,
                    collection: action.collection,
                    usage: action.usage,
                    application: {id: 6, code: "Energi" }
                }
            };
        case "SET_ISPARK":
            return {
                ... state,
                IsPark: action.IsPark,
                Well: {
                    ...state.Well,
                    collection: action.IsPark === false ? {id: "", serialNumber: 0, areaName: ""} : state.Well.collection,
                    application: action.IsPark === true ? {id: 6, code: "Energi"} : {id: 0, code: ""},
                    usage: action.IsPark === true ? {id: 21, code: "Større anlegg"} : {id: 0, code: ""}
                }};
        case "SET_PARK_USAGECODE":
            return {
                ...state,
                Park: {
                    ...state.Park,
                    usage: action.code
                }
            };
        case "RESET_PARK":
            return {...state,
                IsPark: false,
                loading: false,
                Park: {
                    id: "",
                    areaName: "",
                    employer: {
                        name: "",
                        phoneNumber: "",
                        email: ""
                    },
                    attachments: [],
                    drillingDate: null,
                    reference: "",
                    drillingRig: "",
                    comment: "",
                    wells: [],
                    location: {
                        performedByOrganizationAddress: "",
                        county: "",
                        municipality: ""
                    },
                    status: "DRAFT",
                    usage: {id: 21, code: "Større anlegg"}
                }
            };
        case "GET_PARKS":
            return {
                ...state,
                loading: false,
                createParkToggle: false,
                Parks: action.data
            };
        case "FAILED_GET_PARKS":
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case "SELECT_PARK":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    collection: action.val
                }
            };
        case "SET_SAVED_PARK":
            return {
                ...state,
                savedPark: action.val
            };
        case "SET_WELL_STATUS":
            return {
                ...state,
                Well: {
                    ...state.Well,
                    status: action.val
                }
            };
        case "GET_PRV_PARK_WELL":
            return {
                ...state,
                zoomLevel: action.data.location.coordinates === null ? 4 : action.data.location.coordinates.latitude > 0 ? 18 : 4,
                loading: false,
                MapCenter:action.data.location.coordinates !== null ?
                    action.data.location.coordinates.latitude > 0 ? [action.data.location.coordinates.latitude.toString(), action.data.location.coordinates.longitude.toString()]
                        : [ "65.2063772", "15.7548828"] : [ "65.2063772", "15.7548828"],
                Well: {
                    ...state.Well,
                    drillingDate: state.Well.drillingDate === null ? action.data.drillingDate === null ? null : moment(action.data.drillingDate) : state.Well.drillingDate,
                    usage: action.data.usage.id === 21 ? state.Well.usage : action.data.usage,
                    drillingRig: state.Well.drillingRig === "" ? action.data.drillingRig === null ? "" : action.data.drillingRig : state.Well.drillingRig,
                    employer: {
                        ...state.Well.employer,
                        name: state.Well.employer.name === "" ? action.data.employer.name === null ? "" : action.data.employer.name: state.Well.employer.name,
                        phoneNumber: state.Well.employer.phoneNumber === "" ? action.data.employer.phoneNumber  === null ? "" : action.data.employer.phoneNumber : state.Well.employer.phoneNumber,
                        email: action.data.employer.email === null ? "" : state.Well.employer.email === "" ? action.data.employer.email : state.Well.employer.email
                    },
                    casing: {
                        ...state.Well.casing,
                        material: state.Well.casing.material.id > 0 ? state.Well.casing.material :
                            action.data.casing === null ? {id: 0, code: "" } : action.data.casing.material === null ? {id: 0, code: "" } : action.data.casing.material,
                        diameter: state.Well.casing.diameter !== "" ? state.Well.casing.diameter : (action.data.casing === null ? "" : action.data.casing.diameter === null ? "" : action.data.casing.diameter.toString())
                    },
                    boreholeDiameter: state.Well.boreholeDiameter === "" ? action.data.boreholeDiameter=== null ? ""  : action.data.boreholeDiameter.toString() : state.Well.boreholeDiameter
                }
            };
        case "FAILED_GET_PRV_PARK_WELL":
            return {
                ...state,
                loading: false
            };
        case "GET_WELL_ATTACHMENTS":
            return {
                ...state,
                loading: false,
                Well: {
                    ...state.Well,
                    attachments: action.data
                }
            };
        case "FAILED_GET_WELL_ATTACHMENTS":
            return {
                ...state,
                loading: false
            };
        case "CHANGE_NGU_SELECTED_COMPANY":
            return {
                ...state,
                NGUSelectedCompany: action.value,
                NGUSelectedCompanyName: action.name,
                NGUSelectedCompanyUUID: action.uuid
            };
        default:
            return state;
    }
};
